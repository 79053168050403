import React, { useEffect, useState } from "react";
import Content from "../components/core/layout/Content";
import Header from "../components/core/Header";
import HeaderActions from "../components/core/HeaderActions";
import { useTranslation } from "react-i18next";
import { Description, FilterList } from "@material-ui/icons";
import { useDevice } from "../hooks/useDevice";
import CircleButton from "../components/core/CircleButton";
import { Box, Button, Collapse, makeStyles } from "@material-ui/core";
import Datagrid from "../components/core/Datagrid";
import NoInvoice from "../features/invoices/components/NoInvoice";
import InvoicesFilters from "../features/invoices/components/InvoicesFilters";
import { Invoice } from "../features/invoices/types/InvoiceTypes";
import { useInvoices } from "../features/invoices/hooks/useInvoices";

const Invoices: React.FC = () => {
  const classes = makeStyles((theme) => ({
    root: {
      backgroundColor: "white",
      padding: theme.spacing(4),
      boxShadow: "0px 5px 15px rgba(115, 55, 0, 0.15)",
      borderRadius: theme.spacing(0.5),
      justifyContent: "center",
    },
  }))();
  const { t } = useTranslation();
  const device = useDevice();
  const [page, setPage] = useState(1);
  const rowsPerPage = 10;
  const [total, setTotal] = useState<number>(0);
  const [filters, setFilters] = useState<string[]>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [displayFilter, setDisplayFilter] = useState(false);
  const { searchInvoices } = useInvoices();

  useEffect(() => {
    setLoading(true);
    searchInvoices(rowsPerPage, page, filters, { tms: "DESC" })
      .then((res) => {
        setLoading(false);
        setInvoices(res.results);
        setTotal(res.total);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setLoading(false));
  }, [filters, page, rowsPerPage, searchInvoices]);
  return (
    <>
      <Header
        focus={false}
        title={t("MenuInvoices")}
        titleIcon={<Description />}
      >
        <HeaderActions>
          {device.isMobile ? (
            <>
              <CircleButton
                color="primary"
                onClick={() => {
                  setDisplayFilter(!displayFilter);
                }}
              >
                <FilterList />
              </CircleButton>
            </>
          ) : (
            <>
              <Button
                color="primary"
                endIcon={<FilterList />}
                onClick={() => {
                  setDisplayFilter(!displayFilter);
                }}
              >
                {t("SortOrFilter")}
              </Button>
            </>
          )}
        </HeaderActions>
      </Header>
      <Content>
        {total === 0 ? (
          <Box className={classes.root}>
            <NoInvoice />
          </Box>
        ) : (
          <>
            <Collapse in={displayFilter}>
              <InvoicesFilters applyFilters={setFilters} />
            </Collapse>
            <Datagrid
              rows={invoices.map((invoice) => {
                return { ...invoice };
              })}
              rowsPerPage={rowsPerPage}
              page={page}
              total={total}
              enablePageSelector
              onChangePage={(p) => setPage(p)}
              columnsDef={[
                { header: "Référence", format: (value) => value.ref },
                {
                  header: "Etat",
                  format: (value) => value.status,
                },
                {
                  header: "Réf intervention",
                  format: (value) => value.intervention.ref,
                },
                {
                  header: "Date demande",
                  format: (value) => value.intervention.dateCreat,
                },
                {
                  header: "Nom distributeur",
                  format: (value) => value.distributor.name,
                },
                {
                  header: "Code distributeur",
                  format: (value) => value.distributor.distributerCode,
                },
                {
                  header: "Garage",
                  format: (value) => value.garage.name,
                },
                {
                  header: "Code client",
                  format: (value) => value.garage.customerCode,
                },
                {
                  header: "Code magasin",
                  format: (value) => value.garage.shopCode,
                },
                {
                  header: "Date émission",
                  format: (value) => value.issueDate,
                },
                {
                  header: "Date échéance",
                  format: (value) => value.dueDate,
                },
                {
                  header: "MONTANT HT €",
                  format: (value) => value.amount,
                },
              ]}
              loading={isLoading}
            />
          </>
        )}
      </Content>
    </>
  );
};

export default Invoices;
