import React, { CSSProperties } from "react";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import {
  Box,
  Drawer,
  useTheme,
  Typography,
  IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../auth/hooks/useAuth";
import CompanyLogo from "../../../components/core/CompanyLogo";
import CloseIcon from "@material-ui/icons/Close";
import { useMenu } from "../hooks/useMenu";
import AppConstant from "../../../utils/appConstant";
import { useConfiguration } from "../../../hooks/useConfiguration";
import { Link } from "react-router-dom";
import CallMadeIcon from "@material-ui/icons/CallMade";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ChatBubbleOutlineIcon from "@material-ui/icons/ChatBubbleOutline";
import { useDevice } from "../../../hooks/useDevice";
import {
  AccountCircleOutlined,
  Add,
  Apartment,
  Build,
  DescriptionOutlined,
  EmojiTransportation,
  HomeOutlined,
  PieChartOutlined,
} from "@material-ui/icons";

const useStyle = makeStyles((theme) => ({
  link: {
    textDecoration: "none",
    "&:hover": {
      cursor: "pointer",
      "& > div": {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
      },
    },
  },
  active: {
    "& > div": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    "&:hover": {
      cursor: "default",
    },
  },
  appbar: {
    height: theme.spacing(10),
    display: "flex",
    backgroundColor: theme.palette.background.default,
    justifyContent: "center",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  entry: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    color: theme.palette.text.primary,
    borderRadius: theme.spacing(0.5),
    margin: `${theme.spacing(2)}px 0`,
    textDecoration: "none",
  },
  drawer: {
    minHeight: "100vh",
    width: AppConstant.drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    zIndex: theme.zIndex.drawer,
  },
  drawerContainer: {
    boxSizing: "border-box",
    minHeight: "100%",
    padding: 24,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  drawerOpen: {
    width: AppConstant.drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor: theme.palette.background.default,
    borderRight: "none",
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: AppConstant.drawerWidthClose,
    backgroundColor: theme.palette.background.default,
    borderRight: "none",
  },
  drawerOpenSmartphone: {
    width: "100vw",
    height: "100vh",
  },
  drawerCloseSmartphone: {
    width: 0,
  },
  divider: {
    borderColor: theme.palette.text.primary,
    borderTop: "1px solid white",
    opacity: 0.12,
    flexGrow: 1,
    marginRight: 8,
  },
}));

const SideMenu: React.FC = () => {
  const { logout } = useAuth();
  const { t } = useTranslation();
  const menu = useMenu();
  const device = useDevice();
  const { user } = useAuth();
  const classes = useStyle();
  const configuration = useConfiguration();

  const prepareContactMenuLabel = () => {
    let label = t("ContactUs");
    if (user?.permissions.contactDistributor) {
      label = t("ContactDistributor");
    }
    if (user?.permissions.contactCentrale) {
      label = t("ContactCentrale");
    }
    return label;
  };

  const handleLogoutClick = () => {
    logout();
  };

  return (
    <>
      <Drawer
        open={menu.isOpen}
        variant={device.isMobile ? "temporary" : "permanent"}
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: menu.isOpen,
          [classes.drawerClose]: !menu.isOpen,
          [classes.drawerOpenSmartphone]: menu.isOpen && device.isMobile,
          [classes.drawerCloseSmartphone]: !menu.isOpen && device.isMobile,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: menu.isOpen,
            [classes.drawerClose]: !menu.isOpen,
            [classes.drawerOpenSmartphone]: menu.isOpen && device.isMobile,
            [classes.drawerCloseSmartphone]: !menu.isOpen && device.isMobile,
          }),
        }}
      >
        <Box className={classes.drawerContainer}>
          <div>
            <MenuHeader />
            <MenuEntry icon={<HomeOutlined />} label={t("Dashboard")} to="/" />
            <MenuGarage />
            <MenuNetwork />
          </div>
          <div>
            <MenuEntry
              icon={<AccountCircleOutlined />}
              label={t("MyAccount")}
              to="/profile"
            />
            <AccountInfos />
            <MenuEntry
              icon={<ExitToAppIcon />}
              label={t("Logout")}
              action={handleLogoutClick}
            />
            <SectionTitle title={t("Toolbox")} />
            {!!configuration.externalUrlToolsMenu && (
              <Link
                to={configuration.externalUrlToolsMenu}
                target="_blank"
                style={{ textDecoration: "none" }}
              >
                <MenuEntry
                  icon={<CallMadeIcon />}
                  label={t("OnlineCatalog")}
                  title={t("OnlineCatalogTitle")}
                />
              </Link>
            )}

            {!user?.permissions.profileCentrale && (
              <MenuEntry
                icon={<ChatBubbleOutlineIcon />}
                label={prepareContactMenuLabel()}
                to="/contact"
              />
            )}

            <ToggleMenu hidden={device.isMobile} />
            <VersionNumber />
          </div>
        </Box>
      </Drawer>
    </>
  );
};

const MenuHeader: React.FC = () => {
  const device = useDevice();
  const menu = useMenu();
  const theme = useTheme();
  const configuration = useConfiguration();
  const declination =
    configuration.themeDeclination.indexOf("dmenu") !== -1 ? "light" : "dark";

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Box
        pb={0}
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "center",
          position: "relative",
        }}
      >
        <CompanyLogo
          variant={menu.isOpen ? "full" : "mini"}
          declination={declination}
        />
        {device.isMobile && (
          <IconButton
            style={{
              color: theme.palette.common.white,
              position: "absolute",
              right: 0,
            }}
            onClick={() => menu.closeMenu()}
          >
            <CloseIcon />
          </IconButton>
        )}
      </Box>
    </div>
  );
};

const MenuGarage: React.FC = () => {
  const { user } = useAuth();
  const { t } = useTranslation();
  return (
    <>
      <MenuEntry
        icon={<Build />}
        label={t("MyIntervention")}
        to="/myinterventions"
        when={() => user?.permissions.myInterventions}
      />
      <MenuEntry
        icon={<Add />}
        label={t("NewIntervention")}
        to="/intervention/new"
        when={() => user?.permissions.createIntervention}
      />
    </>
  );
};

const MenuNetwork: React.FC = () => {
  const { user } = useAuth();
  const { t } = useTranslation();

  const displayMenu =
    user?.permissions.networkInterventions ||
    user?.permissions.invoices ||
    user?.permissions.viewNetworkGarages ||
    user?.permissions.createNetworkGarage ||
    user?.permissions.statistics ||
    user?.permissions.adminDistributors;

  return (
    <>
      {displayMenu && (
        <>
          <SectionTitle title={t("Network")} />
          <MenuEntry
            icon={<Build />}
            label={t("MenuNetworkInterventions")}
            to="/networkinterventions"
            when={() => user?.permissions.networkInterventions}
          />
          <MenuEntry
            icon={<PieChartOutlined />}
            label={t("MenuStatistics")}
            to="/statistics"
            when={() => user?.permissions.statistics}
          />
          <MenuEntry
            icon={<DescriptionOutlined />}
            label={t("MenuInvoices")}
            to="/invoices"
            when={() => user?.permissions.invoices}
          />
          <MenuEntry
            icon={<EmojiTransportation />}
            label={t("MenuGarages")}
            to="/garages"
            when={() => user?.permissions.viewNetworkGarages}
          />
          <MenuEntry
            icon={<Add />}
            label={t("MenuNewGarage")}
            to="/garage/new"
            when={() => user?.permissions.createNetworkGarage}
          />
          <MenuEntry
            icon={<Apartment />}
            label={t("MenuDistributors")}
            to="/distributors"
            when={() => user?.permissions.adminDistributors}
          />
          <MenuEntry
            icon={<Add />}
            label={t("MenuNewDistributor")}
            to="/distributor/new"
            when={() => user?.permissions.adminDistributors}
          />
        </>
      )}
    </>
  );
};

const MenuEntry: React.FC<{
  icon: React.ReactNode;
  label: string;
  title?: string;
  to?: string;
  action?: React.MouseEventHandler<HTMLElement>;
  when?: () => boolean | undefined;
}> = (props) => {
  const classes = useStyle();
  const menu = useMenu();
  const device = useDevice();

  return (
    <>
      {(!props.when || props.when()) &&
        (props.to ? (
          <NavLink
            to={props.to}
            className={({ isActive }) =>
              isActive ? clsx(classes.link, classes.active) : classes.link
            }
            onClick={() => device.isMobile && menu.closeMenu()}
          >
            <Box p={1} className={classes.entry} title={props.title}>
              <Box display="flex" justifyContent="center">
                {props.icon}
              </Box>
              {menu.isOpen && (
                <Box ml={2}>
                  <Typography variant="body2">{props.label}</Typography>
                </Box>
              )}
            </Box>
          </NavLink>
        ) : (
          <Box className={classes.link}>
            <Box
              p={1}
              className={classes.entry}
              onClick={props.action}
              title={props.title}
            >
              <Box display="flex" justifyContent="center">
                {props.icon}
              </Box>
              {menu.isOpen && (
                <Box ml={2}>
                  <Typography>{props.label}</Typography>
                </Box>
              )}
            </Box>
          </Box>
        ))}
    </>
  );
};

const Divider: React.FC = () => {
  const classes = useStyle();
  return <div className={classes.divider} />;
};

const SectionTitle: React.FC<{ title?: string; style?: CSSProperties }> = (
  props,
) => {
  const theme = useTheme();
  const menu = useMenu();
  return (
    <div style={{ display: "flex", flexWrap: "nowrap", alignItems: "center" }}>
      <Divider />
      {menu.isOpen && (
        <Typography style={{ color: theme.palette.text.hint, fontSize: 12 }}>
          {props.title}
        </Typography>
      )}
    </div>
  );
};

const AccountInfos: React.FC = () => {
  const { user } = useAuth();
  const menu = useMenu();
  const { t } = useTranslation();
  return (
    <>
      {menu.isOpen && (
        <>
          {user?.thirdparty.customerCode && (
            <InfoLineEntry
              label={t("SideMenuClientCode")}
              value={user?.thirdparty.customerCode}
            />
          )}
          {user?.thirdparty.shopCode && (
            <InfoLineEntry
              label={t("SideMenuShopCode")}
              value={user?.thirdparty.shopCode}
            />
          )}
          {user?.thirdparty.distributerCode && (
            <InfoLineEntry
              label={t("SideMenuDistributorCode")}
              value={user?.thirdparty.distributerCode}
            />
          )}
        </>
      )}
    </>
  );
};

const InfoLineEntry: React.FC<{ label: string; value: string }> = (props) => {
  const theme = useTheme();
  const classes = makeStyles({
    root: {
      display: "flex",
      flexWrap: "nowrap",
      alignItems: "center",
      height: 20,
      marginTop: theme.spacing(0.5),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    text: {
      fontWeight: 400,
      fontSize: 12,
      color: theme.palette.text.hint,
    },
    value: {
      textTransform: "uppercase",
    },
  })();
  return (
    <div className={classes.root}>
      <Typography className={classes.text}>{props.label}</Typography>
      <div
        className={classes.text}
        style={{
          flex: 1,
          borderTop: "1px dashed",
          marginLeft: theme.spacing(2),
          marginRight: theme.spacing(2),
        }}
      />
      <Typography className={clsx(classes.text, classes.value)}>
        {props.value}
      </Typography>
    </div>
  );
};

const ToggleMenu: React.FC<{ hidden: boolean }> = (props) => {
  const menu = useMenu();
  const { t } = useTranslation();
  return (
    <>
      {!props.hidden && (
        <MenuEntry
          icon={menu.isOpen ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />}
          label={t("ReduceMenu")}
          action={menu.toggleMenu}
        />
      )}
    </>
  );
};

const VersionNumber: React.FC = () => {
  const menu = useMenu();
  return (
    <div style={{ width: "100%", textAlign: "center", marginTop: "1rem" }}>
      <Typography variant="caption">
        {`${menu.isOpen ? "Version " : ""}${import.meta.env.VITE_VERSION}`}
      </Typography>
    </div>
  );
};

export default SideMenu;
