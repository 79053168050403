import React, { useState } from "react";

import Memory from "@material-ui/icons/Memory";
import { useTranslation } from "react-i18next";

import Content from "../components/core/layout/Content";
import Header from "../components/core/Header";
import {
  Box,
  Button,
  CircularProgress,
  FormHelperText,
  makeStyles,
  MenuItem,
  Typography,
  useTheme,
} from "@material-ui/core";
import CotroliaFormControl from "../components/core/input/CotroliaFormControl";
import CotroliaInputLabel from "../components/core/input/CotroliaInputLabel";
import CotroliaSelect from "../components/core/input/CotroliaSelect";
import UploadButton from "../components/core/UploadButton";
import Send from "@material-ui/icons/Send";
import CotroliaTextField from "../components/core/input/CotroliaTextField";
import { AlertCustomOptions, useAlert } from "react-alert";
import { formatSize } from "../utils/validation";
import ContentCard from "../components/core/layout/ContentCard";
import { useContactForm } from "../features/contactform/hooks/useContactForm";
import { useAuth } from "../features/auth/hooks/useAuth";

const useStyle = makeStyles((theme) => ({
  title: {
    color: theme.palette.text.primary,
  },
  titleDesc: {
    color: theme.palette.text.secondary,
  },
}));

const Contact = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyle();
  const alert = useAlert();
  const { user } = useAuth();
  const services: { key: string; label: string }[] = [
    ...(!user?.permissions.contactDistributor &&
    !user?.permissions.contactCentrale
      ? [
          { key: "COMMERCIAL", label: t("ContactUsServiceCOMMERCIAL") },
          { key: "ADMINISTRATION", label: t("ContactUsServiceADMINISTRATION") },
          { key: "TECHNICAL", label: t("ContactUsServiceTECHNICAL") },
        ]
      : []),
    ...(user?.permissions.contactDistributor
      ? [{ key: "DISTRIBUTOR", label: t("ContactDistributor") }]
      : []),
    ...(user?.permissions.contactCentrale
      ? [{ key: "CENTRALE", label: t("ContactCentrale") }]
      : []),
  ];

  const preparePageTitle = () => {
    let label = t("ContactUs");
    if (user?.permissions.contactDistributor) {
      label = t("ContactDistributorTitle");
    }
    if (user?.permissions.contactCentrale) {
      label = t("ContactCentraleTite");
    }
    return label;
  };

  const prepareDesc = () => {
    let desc = t("ContactUsDesc");
    if (user?.permissions.contactDistributor) {
      desc = t("ContactDistributorDesc");
    }
    if (user?.permissions.contactCentrale) {
      desc = t("ContactCentraleDesc");
    }
    return desc;
  };

  const [service, setService] = useState(
    services.length === 1 ? services[0].key : "",
  );
  const [serviceError, setServiceError] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const [filesError, setFilesError] = useState("");
  const [message, setMessage] = useState("");
  const [messageError, setMessageError] = useState(false);
  const [loading, setLoading] = useState(false);
  const { sendEmail } = useContactForm();

  /**
   * Method to check if we can disable the button
   *
   * @returns {boolean}
   */
  const hasError = () => service === "" || message === "";

  /**
   * Clear the form
   */
  const clearForm = () => {
    setService("");
    setMessage("");
    setFiles([]);
  };

  /**
   * Method to send the email
   */
  const handleClick = () => {
    let error = false;

    if (service === "") {
      setServiceError(true);
      error = true;
    }

    if (message === "") {
      setMessageError(true);
      error = true;
    }

    // Verify file sizes. We must not exceed 1MB
    let totalSize = 0;
    for (let i = 0; i < files.length; i++) {
      totalSize += files[i].size;
    }
    if (totalSize > 1000000) {
      setFilesError(t("FilesToLargeMax1MB") + formatSize(totalSize));
      error = true;
    }

    if (!error) {
      setLoading(true);
      // We can submit
      sendEmail(service, message, files)
        .then(() => {
          clearForm();
          setLoading(false);
          const myAlert = alert.show(t("ContactUsSuccessDesc"), {
            title: t("ContactUsSuccessTitle"),
            close: () => {
              alert.remove(myAlert);
            },
            type: "success",
          } as unknown as AlertCustomOptions);
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);

          const myAlert = alert.show(t("ContactUsErrorDesc"), {
            title: t("ContactUsErrorTitle"),
            close: () => {
              alert.remove(myAlert);
            },
            type: "error",
          } as unknown as AlertCustomOptions);
        });
    }
  };

  return (
    <>
      <Header
        focus={false}
        title={preparePageTitle()}
        titleIcon={<Memory />}
        titleDesc=""
      ></Header>
      <Content centered>
        <ContentCard>
          {/* Header */}
          <Typography variant="h4" className={classes.title} align="center">
            {t("ContactUsTitle")}
          </Typography>
          <Typography
            variant="subtitle1"
            className={classes.titleDesc}
            align="center"
            style={{ marginBottom: theme.spacing(2) }}
          >
            {prepareDesc()}
          </Typography>

          {services.length > 1 && (
            <Box style={{ marginBottom: theme.spacing(2) }} width="100%">
              <CotroliaFormControl
                variant="filled"
                required
                error={serviceError}
              >
                <CotroliaInputLabel id="service-label">
                  {t("ContactUsSelectService")}
                </CotroliaInputLabel>
                <CotroliaSelect
                  id="service"
                  labelId="service-label"
                  value={service}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    if (e.target.value === "") {
                      setServiceError(true);
                    } else {
                      setServiceError(false);
                    }
                    setService(e.target.value);
                  }}
                >
                  {services.map((service) => (
                    <MenuItem key={service.key} value={service.key}>
                      {service.label}
                    </MenuItem>
                  ))}
                </CotroliaSelect>
                {serviceError && (
                  <FormHelperText variant="outlined">
                    {t("RequiredFieldService")}
                  </FormHelperText>
                )}
              </CotroliaFormControl>
            </Box>
          )}

          {/* Files */}
          <UploadButton
            parentFiles={files}
            setParentFiles={(files: File[]) => {
              if (filesError !== "") {
                setFilesError("");
              }
              setFiles(files);
            }}
            label={t("LinkDocument")}
            error={filesError !== ""}
            errorMsg={filesError}
            id={"upload-file-contact"}
          />

          {/* Content */}
          <CotroliaFormControl>
            <CotroliaTextField
              label={t("YourMessage")}
              value={message}
              onChange={(
                e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
              ) => {
                if (e.target.value === "") {
                  setMessageError(true);
                } else {
                  setMessageError(false);
                }
                setMessage(e.target.value);
              }}
              multiline
              rows={6}
              required
              error={messageError}
              helperText={messageError ? t("RequiredFieldMessage") : ""}
            />
          </CotroliaFormControl>

          {/* Send button */}
          <Box m={2}>
            <Button
              variant="contained"
              color="primary"
              endIcon={loading ? <CircularProgress size={24} /> : <Send />}
              onClick={handleClick}
              disabled={hasError() || loading}
            >
              {t("Send")}
            </Button>
          </Box>
        </ContentCard>
      </Content>
    </>
  );
};

export default Contact;
