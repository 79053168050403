import {
  alpha,
  Box,
  Checkbox,
  CircularProgress,
  FormControl,
  IconButton,
  makeStyles,
  MenuItem,
  Select,
  Tooltip,
  Typography,
  useTheme,
} from "@material-ui/core";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import React, { CSSProperties } from "react";
import clsx from "clsx";

const useGridStyles = makeStyles((theme) => ({
  header: {
    display: "contents",
    "& div": {
      color: theme.palette.text.secondary,
    },
    "& > div": {
      backgroundColor: theme.palette.background.paper,
      display: "flex",
      alignItems: "center",
      paddingLeft: 10,
    },
  },
  content: {
    display: "contents",
    "& > div": {
      display: "contents",
      "& > div": {
        paddingLeft: 10,
        borderBottom: `1px solid ${theme.palette.grey[200]}`,
        backgroundColor: theme.palette.background.default,
      },
      "&:last-child > div": {
        borderBottom: "none",
      },
      "&:hover > div": {
        backgroundColor: theme.palette.grey[100],
      },
    },
  },
  footer: {
    height: 44,
    display: "flex",
    alignItems: "center",
    gap: 10,
    justifyContent: "space-between",
    backgroundColor: theme.palette.background.paper,
  },
  rowSelect: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: 10,
  },
}));

export type ColumnOrder = "ASC" | "DESC";

export interface ColumnDef<T extends object> {
  header: string;
  sortOnField?: string;
  colWidth?: string;
  style?: React.CSSProperties;
  className?: string;
  format?(value: T, index: number): React.ReactNode;
}

interface DatagridProps<T extends object> {
  columnsDef: ColumnDef<T>[];
  rows: T[];
  rowsPerPage: number;
  page: number;
  total: number;
  enablePageSelector?: boolean;
  enableRowsPerPageSelector?: boolean;
  rowsPerPageOptions?: number[];
  enableSelect?: boolean;
  sort?: Partial<Record<string, ColumnOrder>>;
  firstColSticky?: boolean;
  lastColSticky?: boolean;
  loading?: boolean;
  style?: React.CSSProperties;
  onChangePage?: (page: number) => void;
  onChangeRowsPerPage?: (rowsPerPage: number) => void;
  onSelectChange?: (selection: T[]) => void;
  onSort?: (sort: Partial<Record<string, ColumnOrder>>) => void;
  onRowClick?: (row: T) => void;
}

interface DatagridHeaderProps<T extends object> {
  columnsDef: ColumnDef<T>[];
  numSelected: number;
  rowCount: number;
  enableSelect?: boolean;
  sort?: Partial<Record<string, ColumnOrder>>;
  firstColSticky?: boolean;
  lastColSticky?: boolean;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSort?: (sort: Partial<Record<string, ColumnOrder>>) => void;
}

interface DatagridContentProps<T extends object> {
  columnsDef: ColumnDef<T>[];
  rows: T[];
  selectedRows: T[];
  enableSelect?: boolean;
  loading?: boolean;
  firstColSticky?: boolean;
  lastColSticky?: boolean;
  onToggleSelectRow: (row: T) => void;
  onRowClick?: (row: T) => void;
}

interface DatagridFooterProps {
  page: number;
  rowsPerPage: number;
  total: number;
  enablePageSelector?: boolean;
  onChangePage?: (page: number) => void;
  enableRowsPerPageSelector?: boolean;
  onChangeRowsPerPage?: (rowsPerPage: number) => void;
  rowsPerPageOptions?: number[];
}

interface DataRow<T extends object> extends ColumnDef<T> {
  content: React.ReactNode;
}

interface RowProps<T extends object> {
  columnsDef: ColumnDef<T>[];
  object: T;
  index: number;
  firstColSticky?: boolean;
  lastColSticky?: boolean;
  onClick?: (row: T) => void;
}

interface ColumnProps<T extends object> {
  data: DataRow<T>;
  sticky?: boolean;
}

const Datagrid = <T extends { id?: string }>(props: DatagridProps<T>) => {
  const theme = useTheme();
  const [selected, setSelected] = React.useState<T[]>([]);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSelect = event.target.checked ? props.rows : [];
    setSelected(newSelect);
    props.onSelectChange && props.onSelectChange(newSelect);
  };

  const toggleSelectRow = (row: T) => {
    const alreadySelected = selected.filter((r) => r.id === row.id).length >= 1;
    const newSelect = alreadySelected
      ? selected.filter((r) => r.id !== row.id)
      : [...selected, row];
    setSelected(newSelect);
    props.onSelectChange && props.onSelectChange(newSelect);
  };

  const classes = makeStyles(() => ({
    loader: {
      display: "flex",
      justifyContent: "center",
      alignItems: props.rows.length <= 10 ? "center" : "flex-start",
      position: "absolute",
      paddingTop: props.rows.length <= 10 ? 0 : `${3 * 44}px`,
      top: 44,
      bottom: 44,
      left: 0,
      right: 0,
      backgroundColor: alpha(theme.palette.background.default, 0.5),
      zIndex: 2,
    },
    grid: {
      display: "grid",
      width: "100%",
      overflow: "auto",
      gridTemplateColumns: `${props.enableSelect ? "50px " : ""}${props.columnsDef.map((col) => col.colWidth ?? "1fr").join(" ")}`,
      gridTemplateRows: `44px ${props.rows.length > 0 ? `repeat(${props.rows.length}, 44px)` : ""}`,
    },
  }))();

  return (
    <div
      style={{
        overflow: "hidden",
        borderRadius: 12,
        position: "relative",
      }}
    >
      {props.loading && (
        <div className={classes.loader}>
          <CircularProgress size={24} />
        </div>
      )}
      <div
        style={{
          width: "100%",
          display: "flex",
          ...props.style,
        }}
      >
        <div className={classes.grid}>
          <DatagridHeader
            columnsDef={props.columnsDef}
            numSelected={selected.length}
            onSelectAllClick={handleSelectAllClick}
            rowCount={props.rows.length}
            sort={props.sort}
            firstColSticky={props.firstColSticky}
            lastColSticky={props.lastColSticky}
            onSort={props.onSort}
            enableSelect={props.enableSelect}
          />
          <DatagridContent
            columnsDef={props.columnsDef}
            rows={props.rows}
            selectedRows={selected}
            onToggleSelectRow={toggleSelectRow}
            onRowClick={props.onRowClick}
            enableSelect={props.enableSelect}
            firstColSticky={props.firstColSticky}
            lastColSticky={props.lastColSticky}
            loading={props.loading}
          />
        </div>
      </div>
      <DatagridFooter
        page={props.page}
        rowsPerPage={props.rowsPerPage}
        total={props.total}
        enablePageSelector={props.enablePageSelector}
        onChangePage={props.onChangePage}
        enableRowsPerPageSelector={props.enableRowsPerPageSelector}
        onChangeRowsPerPage={props.onChangeRowsPerPage}
        rowsPerPageOptions={props.rowsPerPageOptions}
      />
    </div>
  );
};

const DatagridHeader = <T extends object>(props: DatagridHeaderProps<T>) => {
  const theme = useTheme();
  const classes = makeStyles(() => ({
    linkContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      "&:hover": {
        "& svg": { opacity: 1 },
      },
    },
    link: {
      display: "flex",
      alignItems: "center",
      textAlign: "start",
      fontSize: 14,
      color: theme.palette.text.primary,
      width: "100%",
      height: "100%",
    },
    linkContent: {
      fontFamily: theme.typography.fontFamily,
      flexShrink: 1,
      maxHeight: "100%",
      boxSizing: "border-box",
      lineHeight: "18px",
      display: "-webkit-box",
      WebkitLineClamp: 1,
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    arrow: {
      opacity: 0,
    },
    active: {
      color: theme.palette.primary.main,
      opacity: 1,
      "& div": {
        color: theme.palette.primary.main,
        opacity: 1,
      },
    },
    sortDisabled: {
      "&:hover": {
        "& svg": {
          opacity: 0,
        },
      },
    },
  }))();
  const gridClasses = useGridStyles();
  return (
    <div className={gridClasses.header}>
      {props.enableSelect && (
        <div className={gridClasses.rowSelect}>
          <Checkbox
            size="small"
            indeterminate={
              props.numSelected > 0 && props.numSelected < props.rowCount
            }
            checked={props.rowCount > 0 && props.numSelected === props.rowCount}
            onChange={props.onSelectAllClick}
            style={{
              color: theme.palette.primary.main,
            }}
          />
        </div>
      )}

      {props.columnsDef.map((columnDef, index) => {
        return (
          <Tooltip title={columnDef.header} key={`column_${index}`}>
            <div
              className={clsx(
                classes.linkContainer,
                columnDef.sortOnField ? "" : classes.sortDisabled,
                columnDef.className,
              )}
              style={{
                ...columnDef.style,
                cursor: columnDef.sortOnField ? "pointer" : "default",
                position:
                  (index === 0 && props.firstColSticky) ||
                  (index === props.columnsDef.length - 1 && props.lastColSticky)
                    ? "sticky"
                    : "static",
                left: index === 0 && props.firstColSticky ? 0 : undefined,
                right:
                  index === props.columnsDef.length - 1 && props.lastColSticky
                    ? 0
                    : undefined,
              }}
              onClick={
                columnDef.sortOnField
                  ? () => {
                      const newSort: Partial<Record<string, ColumnOrder>> = {};
                      newSort[columnDef.sortOnField!] =
                        props.sort &&
                        props.sort[columnDef.sortOnField!] === "ASC"
                          ? "DESC"
                          : "ASC";
                      props.onSort && props.onSort(newSort);
                    }
                  : undefined
              }
            >
              <div style={{ display: "flex" }}>
                <div
                  className={clsx(
                    classes.link,
                    columnDef.sortOnField &&
                      props.sort &&
                      props.sort[columnDef.sortOnField]
                      ? classes.active
                      : "",
                  )}
                  style={{
                    fontWeight: columnDef.sortOnField ? 700 : 400,
                  }}
                >
                  <div className={classes.linkContent}>{columnDef.header}</div>
                </div>
                <ArrowDownwardIcon
                  style={{
                    height: 20,
                    width: 20,
                    marginLeft: 5,
                    marginRight: 5,
                    transition: "0.2s",
                    transform:
                      !columnDef.sortOnField ||
                      (props.sort &&
                        props.sort[columnDef.sortOnField] === "ASC")
                        ? "rotate(0deg)"
                        : "rotate(180deg)",
                  }}
                  className={clsx(
                    classes.arrow,
                    columnDef.sortOnField &&
                      props.sort &&
                      props.sort[columnDef.sortOnField]
                      ? classes.active
                      : "",
                  )}
                />
              </div>
            </div>
          </Tooltip>
        );
      })}
    </div>
  );
};

const DatagridContent = <T extends { id?: string }>(
  props: DatagridContentProps<T>,
) => {
  const theme = useTheme();
  const gridClasses = useGridStyles();

  const isSelected = (row: T) =>
    props.selectedRows.filter((r) => r.id === row.id).length >= 1;
  return (
    <>
      <div className={gridClasses.content}>
        {props.rows.map((row, index) => {
          return (
            <div
              key={index}
              style={{
                cursor: props.onRowClick ? "pointer" : "auto",
              }}
              onClick={() => props.onRowClick && props.onRowClick(row)}
            >
              {props.enableSelect && (
                <div className={gridClasses.rowSelect}>
                  <Checkbox
                    checked={isSelected(row)}
                    onChange={() => props.onToggleSelectRow(row)}
                    size="small"
                    style={{
                      verticalAlign: "text-bottom",
                      color: theme.palette.primary.main,
                    }}
                  />
                </div>
              )}
              <Row
                columnsDef={props.columnsDef}
                object={row}
                index={index}
                firstColSticky={props.firstColSticky}
                lastColSticky={props.lastColSticky}
                onClick={props.onRowClick}
              />
            </div>
          );
        })}
      </div>
    </>
  );
};

const Row = <T extends object>(props: RowProps<T>) => {
  const classes = makeStyles(() => ({
    rowText: {
      lineHeight: "44px",
      display: "-webkit-box",
      WebkitLineClamp: 1,
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
      textOverflow: "ellipsis",
      wordBreak: "break-all",
    },
  }))();
  const rowData = props.columnsDef.map((columnDef, index) => {
    const value = columnDef.format
      ? columnDef.format(props.object, props.index)
      : "";

    const content =
      typeof value === "string" || typeof value === "number" ? (
        <Tooltip title={value.toString()}>
          <Typography variant="body2" className={classes.rowText}>
            {value}
          </Typography>
        </Tooltip>
      ) : (
        <>{value}</>
      );

    const stickyStyle: CSSProperties = {
      position:
        (index === 0 && props.firstColSticky) ||
        (index === props.columnsDef.length - 1 && props.lastColSticky)
          ? "sticky"
          : "static",
      left: index === 0 && props.firstColSticky ? 0 : undefined,
      right:
        index === props.columnsDef.length - 1 && props.lastColSticky
          ? 0
          : undefined,
    };

    return {
      ...columnDef,
      style: {
        ...columnDef.style,
        ...stickyStyle,
      },
      content,
    };
  });

  return (
    <>
      {rowData.map((data, index) => (
        <Column data={data} key={index} />
      ))}
    </>
  );
};

const Column = <T extends object>(props: ColumnProps<T>) => {
  const theme = useTheme();
  const classes = makeStyles(() => ({
    column: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      "& > div": {
        overflow: "hidden",
        paddingRight: 10,
      },
      color: theme.palette.text.secondary,
    },
  }))();
  return (
    <div
      className={clsx(classes.column, props.data.className)}
      style={props.data.style}
    >
      <div>{props.data.content}</div>
    </div>
  );
};

const DatagridFooter = (props: DatagridFooterProps) => {
  const theme = useTheme();
  const gridClasses = useGridStyles();

  const labelDisplayedRows = ({
    from,
    to,
    count,
  }: {
    from: number;
    to: number;
    count: number;
  }) => {
    return `${from}–${to} sur ${count !== -1 ? count : `plus de ${to}`}`;
  };

  const getLabelDisplayedRowsTo = () => {
    if (props.total === -1) {
      return (props.page + 1) * props.rowsPerPage;
    }
    return props.rowsPerPage === -1
      ? props.total
      : Math.min(props.total, (props.page + 1) * props.rowsPerPage);
  };

  return (
    <div className={gridClasses.footer}>
      <Typography
        variant="caption"
        style={{
          marginLeft: theme.spacing(1),
          color: theme.palette.text.primary,
        }}
      >
        {props.total} lignes
      </Typography>
      <div style={{ display: "flex", alignItems: "center" }}>
        {props.enableRowsPerPageSelector && (
          <>
            <Typography variant="body2">Résultats par page:</Typography>
            <FormControl size="small" variant="outlined">
              <Select
                onChange={(
                  e: React.ChangeEvent<{
                    name?: string;
                    value: unknown;
                  }>,
                ) => {
                  if (props.onChangeRowsPerPage) {
                    props.onChangeRowsPerPage(e.target.value as number);
                    if (
                      props.onChangePage &&
                      (e.target.value as number) > props.total
                    ) {
                      props.onChangePage(0);
                    }
                  }
                }}
                value={props.rowsPerPage}
                style={{ fontSize: 14 }}
              >
                {(props.rowsPerPageOptions ?? [10, 50, 100]).map((value) => (
                  <MenuItem key={value} value={value} style={{ fontSize: 14 }}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </>
        )}

        <Typography
          variant="caption"
          style={{
            minWidth: 80,
            textAlign: "center",
            color: theme.palette.text.primary,
          }}
        >
          {labelDisplayedRows({
            from: props.total === 0 ? 0 : 1 + props.page * props.rowsPerPage,
            to: getLabelDisplayedRowsTo(),
            count: props.total === -1 ? -1 : props.total,
          })}
        </Typography>
        {props.enablePageSelector && (
          <Box style={{ display: "flex", gap: 1 }}>
            <IconButton
              size="small"
              disabled={props.page === 0}
              onClick={() =>
                props.onChangePage && props.onChangePage(props.page - 1)
              }
            >
              <KeyboardArrowLeftIcon />
            </IconButton>
            <IconButton
              size="small"
              disabled={
                props.page >= Math.ceil(props.total / props.rowsPerPage) - 1
              }
              onClick={() =>
                props.onChangePage && props.onChangePage(props.page + 1)
              }
            >
              <KeyboardArrowRightIcon />
            </IconButton>
          </Box>
        )}
      </div>
    </div>
  );
};

export default Datagrid;
