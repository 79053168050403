import React, { CSSProperties } from "react";
import { useTranslation } from "react-i18next";
import Header from "../components/core/Header";
import { Button, makeStyles, Typography } from "@material-ui/core";
import LinkIcon from "@material-ui/icons/Link";
import CircleButton from "../components/core/CircleButton";
import Content from "../components/core/layout/Content";
import { useAuth } from "../features/auth/hooks/useAuth";
import ToggleSection from "../components/core/ToggleSection";
import ProfileCompany from "../features/profile/components/ProfileCompany";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import PeopleIcon from "@material-ui/icons/People";
import CotroliaFormControl from "../components/core/input/CotroliaFormControl";
import CotroliaInputLabel from "../components/core/input/CotroliaInputLabel";
import LanguageSelector from "../components/core/LanguageSelector";
import TranslateIcon from "@material-ui/icons/Translate";
import { useConfiguration } from "../hooks/useConfiguration";
import { useDevice } from "../hooks/useDevice";
import { AccountCircle, Person, Store, Telegram } from "@material-ui/icons";
import ProfileContactList from "../features/profile/components/ProfileContactList";
import { ContactType } from "../features/user/types/userTypes";
import ProfileMainContact from "../features/profile/components/ProfileMainContact";

const useStyle = makeStyles((theme) => ({
  btn: {
    backgroundColor: theme.palette.primary.contrastText,
    color: theme.palette.primary.main,
  },
}));

const Profile = () => {
  const { t } = useTranslation();
  const device = useDevice();
  const classes = useStyle();
  const { user, logout } = useAuth();
  const perms = user!.permissions;
  const configuration = useConfiguration();
  const displayBillingContact =
    !user?.individual && !perms.profileBackOffice && !perms.profileDistributer;
  const displayTechnicalContact =
    !user?.individual &&
    !perms.profileBackOffice &&
    !perms.profileCentrale &&
    !perms.profileDistributer;
  const headerTitle = perms.profileGarage ? t("MyGarage") : t("MyAccount");
  const companyTitle = perms.profileGarage
    ? t("YourGarage")
    : t("YourEstablishment");
  const companyDesc = perms.profileGarage
    ? t("YourGarageDesc")
    : t("YourEstablishmentDesc");

  const handleLogoutClick = () => {
    logout();
  };

  return (
    <>
      <Header
        focus={false}
        title={headerTitle}
        titleIcon={<AccountCircle />}
        titleDesc=""
      >
        <div
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <HeaderCodes style={{ marginLeft: 24 }} />
          {device.isMobile ? (
            <CircleButton
              variant="contained"
              className={classes.btn}
              onClick={handleLogoutClick}
            >
              <LinkIcon />
            </CircleButton>
          ) : (
            <Button
              variant="contained"
              endIcon={<LinkIcon />}
              className={classes.btn}
              onClick={handleLogoutClick}
            >
              {t("Logout")}
            </Button>
          )}
        </div>
      </Header>
      <Content centered>
        <ToggleSection
          title={t("MainContact")}
          icon={<Person />}
          description={t("ProfileMainContactDesc")}
          defaultExpanded={true}
        >
          <ProfileMainContact />
        </ToggleSection>

        {!user?.individual && (
          <ToggleSection
            title={companyTitle}
            icon={<Store />}
            description={companyDesc}
            defaultExpanded={true}
          >
            <ProfileCompany />
          </ToggleSection>
        )}

        {displayBillingContact && (
          <ToggleSection
            title={t("BillingContact")}
            icon={<CreditCardIcon />}
            description={t("ProfileBillingContactDesc")}
            defaultExpanded={true}
          >
            <ProfileContactList type={ContactType.Billing} />
          </ToggleSection>
        )}

        {perms.deliveryNote && (
          <ToggleSection
            title={t("DeliveryNoteContact")}
            icon={<Telegram />}
            description={t("ProfileDeliveryNoteContactDesc")}
            defaultExpanded={true}
          >
            <ProfileContactList type={ContactType.DeliveryNote} multiple />
          </ToggleSection>
        )}

        {displayTechnicalContact && (
          <ToggleSection
            title={t("TechnicalContact")}
            icon={<PeopleIcon />}
            description={t("ProfileTechnicalContactDesc")}
            defaultExpanded={true}
          >
            <ProfileContactList
              type={ContactType.Tech}
              multiple
              enableCreateAccount
            />
          </ToggleSection>
        )}

        {configuration.availableLangs.length > 1 && (
          <ToggleSection
            title={t("Language")}
            icon={<TranslateIcon />}
            description={t("LanguageDesc")}
            defaultExpanded={true}
          >
            <CotroliaFormControl variant="filled">
              <CotroliaInputLabel>{t("SelectLanguage")}</CotroliaInputLabel>
              <LanguageSelector />
            </CotroliaFormControl>
          </ToggleSection>
        )}
      </Content>
    </>
  );
};

const HeaderCodes: React.FC<{ style?: CSSProperties }> = (props) => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const classes = makeStyles({
    root: { display: "flex", gap: 24 },
    codeItem: { display: "flex", flexDirection: "column" },
  })();

  return (
    <div className={classes.root} style={props.style}>
      {user?.thirdparty.customerCode && (
        <div className={classes.codeItem}>
          <Typography variant="caption">{t("ProfileClientCode")}</Typography>
          <Typography variant="subtitle2">
            {user?.thirdparty.customerCode}
          </Typography>
        </div>
      )}
      {user?.thirdparty.shopCode && (
        <div className={classes.codeItem}>
          <Typography variant="caption">{t("ProfileShopCode")}</Typography>
          <Typography variant="subtitle2">
            {user?.thirdparty.shopCode}
          </Typography>
        </div>
      )}
      {user?.thirdparty.distributerCode && (
        <div className={classes.codeItem}>
          <Typography variant="caption">
            {t("ProfileDistributorCode")}
          </Typography>
          <Typography variant="subtitle2">
            {user?.thirdparty.distributerCode}
          </Typography>
        </div>
      )}
    </div>
  );
};

export default Profile;
