import React, { useEffect, useState } from "react";
import { Box, makeStyles } from "@material-ui/core";
import DividerWithIcon from "../../../components/core/DividerWithAction";
import { useTranslation } from "react-i18next";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { useNavigate } from "react-router";
import AppConstant from "../../../utils/appConstant";
import { useAuth } from "../../auth/hooks/useAuth";
import NoIntervention from "../../interventions/components/list/NoIntervention";
import { useInterventions } from "../../interventions/hooks/useInterventions";
import InterventionCardList from "../../interventions/components/list/InterventionCardList";
import { Intervention } from "../../interventions/types/InterventionTypes";

const useStyle = makeStyles((theme) => ({
  cardContainer: {
    display: "flex",
    flexWrap: "wrap",
    margin: theme.spacing(2, 0),
    justifyContent: "center",
    "& .MuiCard-root": {
      [theme.breakpoints.up("sm")]: {
        width: AppConstant.interventionCardWidthList,
      },
    },
  },
}));

const DashboardInterventionList: React.FC = () => {
  const classes = useStyle();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState<boolean>(true);
  const [interventionNb, setInterventionNb] = useState<number>(0);
  const [interventions, setInterventions] = useState<Intervention[]>([]);
  const { user } = useAuth();
  const { listIntervention } = useInterventions();
  const CARD_LIMIT = 3;

  useEffect(() => {
    listIntervention(CARD_LIMIT, 1, {}, { datec: "DESC" })
      .then((res) => {
        setLoading(false);
        setInterventions(res.results);
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  }, [listIntervention]);

  useEffect(() => {
    if (user && user.thirdparty) {
      setInterventionNb(user.thirdparty.ongoingInterNb);
    }
  }, [user]);

  const handleAction = () => {
    navigate("/interventions");
  };

  return (
    <>
      <DividerWithIcon
        title={t("MyLastIntervention")}
        icon={<ArrowForwardIcon />}
        action={handleAction}
        actionName={t("OnGoing")}
      />

      {interventionNb > 0 ? (
        <Box className={classes.cardContainer}>
          <InterventionCardList
            isLoading={isLoading}
            interventionNb={
              interventionNb > CARD_LIMIT ? CARD_LIMIT : interventionNb
            }
            interventions={interventions}
          />
        </Box>
      ) : (
        <NoIntervention />
      )}
    </>
  );
};

export default DashboardInterventionList;
