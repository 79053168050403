import { Box, Button, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import MemoryIcon from "@material-ui/icons/Memory";
import { makeStyles } from "@material-ui/styles";
import { useNavigate } from "react-router";
import { useAuth } from "../../../auth/hooks/useAuth";

const useStyle = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(3),
    margin: theme.spacing(2, 0),
    textAlign: "center",
  },
  margin: {
    margin: theme.spacing(1, 0),
  },
}));

const NoIntervention = () => {
  const { t } = useTranslation();
  const classes = useStyle();
  const navigate = useNavigate();
  const { user } = useAuth();

  const handleClick = () => {
    navigate("/intervention/new");
  };

  return (
    <Box className={classes.root}>
      <MemoryIcon fontSize="large" />
      <Typography variant="h5" className={classes.margin}>
        {t("NoIntervention")}
      </Typography>
      <Typography
        variant="body1"
        color="textSecondary"
        className={classes.margin}
      >
        {t("NoInterventionDesc")}
      </Typography>
      {user.permissions.createIntervention && (
        <Button
          variant="contained"
          color="primary"
          endIcon={<AddCircleOutlineIcon />}
          className={classes.margin}
          onClick={handleClick}
        >
          {t("NewIntervention")}
        </Button>
      )}
    </Box>
  );
};

export default NoIntervention;
