import React from "react";
import { Typography, Button, Box, useTheme } from "@material-ui/core";
import moment from "../../../../utils/moment";
import InterventionCard, {
  InterventionCardContentLine,
} from "./InterventionCard";
import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import NoFilterResult from "../../../../components/core/NoFilterResult";
import { useNavigate } from "react-router-dom";
import { useInterventionStatus } from "../../hooks/useInterventionStatus";
import {
  Intervention,
  InterventionStatus,
} from "../../types/InterventionTypes";
import InterventionCardSkeleton from "./InterventionCardSkeleton";

const useCardStyle = makeStyles((theme) => ({
  cardMargin: {
    margin: theme.spacing(0, 2, 2, 0),
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(0, 0, 2, 0),
    },
  },
}));

const InterventionCardList: React.FC<{
  isLoading: boolean;
  interventionNb: number;
  interventions: Intervention[];
}> = (props) => {
  return (
    <>
      {props.interventions && props.isLoading === false ? (
        props.interventions.length > 0 ? (
          props.interventions.map((item, index) => (
            <InterventionCardListItem key={index} item={item} />
          ))
        ) : (
          <NoFilterResult />
        )
      ) : (
        <InterventionListSkeleton interventionNb={props.interventionNb} />
      )}
    </>
  );
};

const InterventionCardListItem: React.FC<{ item: Intervention }> = ({
  item,
}) => {
  const classes = useCardStyle();
  const theme = useTheme();
  const { getStatusDisplay } = useInterventionStatus();
  const statusDisplay = getStatusDisplay(item.status);
  const { t } = useTranslation();

  return (
    <Box className={classes.cardMargin}>
      <InterventionCard
        interId={item.id}
        interRef={item.ref}
        icon={getStatusDisplay(item.status).icon}
        footer={t("LastEdit") + moment.unix(item.dateUpdate).fromNow()}
        className={""}
      >
        {item.customerRef && (
          <InterventionCardContentLine
            leftContent={t("YourRef")}
            rightContent={item.customerRef}
          />
        )}

        <InterventionCardContentLine
          leftContent={t("Manager")}
          rightContent={item.userCreat}
        />

        <Box
          display="flex"
          flexDirection="column"
          style={{ marginTop: theme.spacing(2) }}
        >
          <StatusButton intervention={item} />
          <Typography variant="body2" color="textSecondary" align="center">
            {statusDisplay.desc}
          </Typography>
        </Box>
      </InterventionCard>
    </Box>
  );
};

const StatusButton: React.FC<{ intervention: Intervention }> = (props) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { getStatusDisplay } = useInterventionStatus();
  const status = parseInt(props.intervention.status.toString());
  const statusDisplay = getStatusDisplay(status);
  let url = "/intervention/" + props.intervention.id;
  if (status === InterventionStatus.QuoteReceive) {
    url = "/intervention/" + props.intervention.id + "/proposal";
  }
  if (status === InterventionStatus.Registered) {
    url = "/intervention/new?id=" + props.intervention.id;
  }

  const btnClasses = makeStyles({
    btn: {
      marginBottom: theme.spacing(2),
      color: getStatusDisplay(status).textColor,
      backgroundColor: statusDisplay.backgroundColor,
      "&:hover": {
        backgroundColor: statusDisplay.backgroundColor,
      },
    },
  })();

  return (
    <Button
      variant={status === InterventionStatus.Transmited ? "text" : "contained"}
      startIcon={statusDisplay.buttonIcon}
      className={btnClasses.btn}
      onClick={() => {
        navigate(url);
      }}
    >
      {statusDisplay.action}
    </Button>
  );
};

const InterventionListSkeleton: React.FC<{ interventionNb: number }> = (
  props,
) => {
  const classes = useCardStyle();

  const skeletons = [];
  for (let i = 0; i < props.interventionNb; i++) {
    skeletons.push(
      <Box key={i} className={classes.cardMargin}>
        <InterventionCardSkeleton />
      </Box>,
    );
  }

  return <>{...skeletons}</>;
};

export default InterventionCardList;
