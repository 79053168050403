import { useCallback } from "react";
import { useApi } from "../../../hooks/useApi";
import {
  Intervention,
  InterventionFilter,
  InterventionInvoice,
  InterventionProposal,
  InterventionSort,
} from "../types/InterventionTypes";

export const useInterventions = (): {
  getIntervention: (id: string) => Promise<Intervention>;
  getLastIntervention: () => Promise<Intervention>;
  getInterventionProposal: (id: string) => Promise<InterventionProposal>;
  getInterventionInvoice: (id: string) => Promise<InterventionInvoice>;
  markPropalAsRead: (id: string) => Promise<void>;
  markPropalAsRefused: (id: string, withReturn: boolean) => Promise<void>;
  setPaymentMethod: (id: string, paymentMethod: string) => Promise<void>;
  listIntervention: (
    limit: number,
    page: number,
    search: InterventionFilter,
    sort: InterventionSort,
  ) => Promise<{ results: Intervention[]; total: number }>;
  listOldIntervention: (
    search: string,
    limit?: number,
  ) => Promise<{ [key: string]: string }>;
} => {
  const { call } = useApi();

  const getIntervention = (id: string): Promise<Intervention> =>
    call("/supercotrolia/interventions/" + id);

  const getLastIntervention = (): Promise<Intervention> =>
    call("/supercotrolia/interventions/latest");

  const getInterventionProposal = (id: string): Promise<InterventionProposal> =>
    call("/supercotrolia/interventions/" + id + "/proposal");

  const getInterventionInvoice = (id: string): Promise<InterventionInvoice> =>
    call("/supercotrolia/interventions/" + id + "/invoice");

  const markPropalAsRead = (id: string) =>
    call("/supercotrolia/interventions/" + id + "/proposal/accept", {
      method: "POST",
    });

  const markPropalAsRefused = (id: string, withReturn: boolean = false) =>
    call("/supercotrolia/interventions/" + id + "/proposal/refuse", {
      data: {
        withReturn,
      },
    });

  const setPaymentMethod = (id: string, paymentMethod: string) =>
    call("/supercotrolia/interventions/" + id + "/invoice", {
      data: {
        paymentMethod,
      },
    });

  const listIntervention = useCallback(
    (
      limit: number,
      page: number,
      search: InterventionFilter,
      sort: InterventionSort,
    ): Promise<{ results: Intervention[]; total: number }> =>
      call("/supercotrolia/interventions/search", {
        data: {
          limit,
          page,
          search,
          order: sort,
        },
      }),
    [call],
  );

  const listOldIntervention = (
    search: string,
    limit: number = 10,
  ): Promise<{ [key: string]: string }> =>
    call(
      "/supercotrolia/interventions/closed/search?search=" +
        search +
        "&limit=" +
        limit,
    );

  return {
    getIntervention,
    getLastIntervention,
    getInterventionProposal,
    getInterventionInvoice,
    markPropalAsRead,
    markPropalAsRefused,
    setPaymentMethod,
    listIntervention,
    listOldIntervention,
  };
};
